import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="A Propos" />
      <div id="about">
        <h2>A Propos de NovMob</h2>
        <p>
          <strong>NovMob</strong> est un blog à propos des nouvelles mobilités
        </p>

        <p>
        </p>

        <div className="about-icons">
          <span role="img" aria-label="Laptop.">
            💻
          </span>
          <span role="img" aria-label="Rocket.">
            🚀
          </span>
        </div>

        <h3>Merci !!</h3>
      </div>
    </Layout>
  )
}
export default AboutPage
